import * as React from "react";
import { graphql } from "gatsby";
import TemplatePage from "../../../../components/pages/templates/TemplatePage";

const Template = ({ data }) => (
  <TemplatePage
    title="Music Streaming App Template | Uizard"
    canonical="/templates/mobile-app-templates/music-streaming-app/"
    category="Tablet Templates"
    url="/templates/tablet-templates/music-streaming-tablet-app/"
    metaDescription="If you are interested in building your own media streaming service, AudioX is here to expedite your design project. Try now with Uizard."
    description="
    h2:Introducing AudioX, a music streaming app design template for tablet
    <br/>
    Say hello to AudioX, a music streaming tablet app template to fast-track the design of your own music streaming service. As with all our templates, AudioX has everything you could possibly need for <a:https://uizard.io/prototyping/>UI prototyping</a> your own digital product idea.
    <br/>
    h3:A music streaming tablet app template with all the UX considerations baked in
    <br/>
    If you are interested in building your own media streaming service, AudioX is here to expedite your design project. Designed in the vein of the most popular streaming apps out there, AudioX comes with all the screens needed to make your own design a success.
    <br/>
    h3:Design your own music streaming tablet app in seconds
    <br/>
    Uizard <a:https://uizard.io/templates/tablet-templates/>tablet app design templates</a> come with all the necessary user flows and click journeys built in, helping you to finish your design in next to no time. Simply sign up to Uizard, open the template, and get to work designing your project.
    "
    pages={[
      "Screens of the login flow (sign-up, sign-in, user registration)",
      "Home screen",
      "Content search screen",
      "Personal library screen",
    ]}
    projectCode="gWy4gG5yMwHgepzqjy8A"
    img1={data.image1.childImageSharp}
    img1alt="music streaming tablet app design template cover"
    img2={data.image2.childImageSharp}
    img2alt="music streaming tablet app design template home screen"
    img3={data.image3.childImageSharp}
    img3alt="music streaming tablet app design template play screen"
    img4={data.image4.childImageSharp}
    img4alt="music streaming tablet app design template discover screen"
    img5={data.image5.childImageSharp}
    img5alt="music streaming tablet app design template summary"
  />
);

export default Template;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }

    image1: file(
      relativePath: {
        eq: "templates/music-streaming-tablet-app/music-tab-app-cover.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image2: file(
      relativePath: {
        eq: "templates/music-streaming-tablet-app/music-tab-app-home.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image3: file(
      relativePath: {
        eq: "templates/music-streaming-tablet-app/music-tab-app-play.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image4: file(
      relativePath: {
        eq: "templates/music-streaming-tablet-app/music-tab-app-discover.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image5: file(
      relativePath: {
        eq: "templates/music-streaming-tablet-app/music-tab-app-summary.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`;
